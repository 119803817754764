import { render, staticRenderFns } from "./stepsH5.vue?vue&type=template&id=20513226&scoped=true&"
import script from "./stepsH5.vue?vue&type=script&lang=ts&"
export * from "./stepsH5.vue?vue&type=script&lang=ts&"
import style0 from "./stepsH5.vue?vue&type=style&index=0&id=20513226&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20513226",
  null
  
)

export default component.exports