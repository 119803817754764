












import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "stepsBank",
})
export default class extends Vue {
  @Prop({required: true}) private active!: number
  @Prop({default: []}) private stepOps!: string[]
  get isShowStepTitle() {
    return document.body.getBoundingClientRect().width > 768
  }
  created() {
  }
}
