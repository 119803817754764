










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ required: true }) private active!: number
  private titles: any[] = []
  mounted() {
    this.titles = [this.$t('online.stepsH5.step1'), this.$t('online.stepsH5.step2'), this.$t('online.stepsH5.step3')]
  }
}
