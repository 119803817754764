














import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "bottom",
})
export default class extends Vue {
  created() {
  }
}
